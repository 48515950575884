<template>
  <ul class="default">
    <template v-if="globalStore.state.loadingState !== LoadingState.FILTERING">
      <li
        v-for="item in items"
        :id="'standard-default-list-item-id-' + item.config?.id"
        :key="item.config?.id"
        class="item"
        @mouseover="hightlightItem(item.config?.id)"
        @mouseout="unhighlightItem(item.config?.id)"
      >
        <TeaserList
          :content="item.content"
          :config="item.config"
          :banner="item.banner"
          :buttons="item.buttons"
          :fig="item.fig"
          :layout="item.layout"
          :text="item.text"
        />
      </li>
    </template>

    <template v-if="globalStore.state.loadingState !== LoadingState.LOADED">
      <li v-for="placeholder in 10" class="item">
        <TeaserListPlaceholder>{{ placeholder }}</TeaserListPlaceholder>
      </li>
    </template>
  </ul>
</template>

<script lang="ts" setup>
import type { Nullable } from '@models/CustomUtilityTypes';
import type { List } from '../models';
import { LoadingState } from '@stores/globalStore';
const globalStore = useGlobalStore();

const props = defineProps<{
  content: List;
  activeItemId?: number | null;
}>();

const emit = defineEmits(['item-mouseover', 'item-mouseout']);

const items = computed(() => props.content.items);

const hightlightItem = (itemId: Nullable<number>): void => {
  if (isEmpty(itemId)) return;

  emit('item-mouseover', itemId);
};

const unhighlightItem = (itemId: Nullable<number>): void => {
  if (isEmpty(itemId)) return;

  emit('item-mouseout', itemId);
};

watch(
  () => props.activeItemId,
  (itemId: number | null) => {
    if (itemId) {
      const root = getRootDomElement();

      const element = root.getElementById(
        'standard-default-list-item-id-' + itemId
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
);
</script>

<style src="./Default.scss" scoped lang="scss"></style>
