<template>
  <article :class="{ highlight: layout?.isHighlighted }" class="teaserList">
    <div class="layout">
      <TeaserListFig
        v-if="fig"
        :content="fig.content"
        :config="fig?.config"
        :layout="fig.layout"
        :fig-fallback="fig.figFallback"
      />

      <div class="right">
        <TeaserListText
          v-if="text"
          :content="text?.content"
          :config="text?.config"
          :layout="text?.layout"
          :title="text?.title"
          :info-tags="text?.infoTags"
          :datelist="text?.datelist"
        />

        <TeaserListButtons
          v-if="buttons"
          :content="buttons?.content"
          :config="buttons?.config"
          :layout="buttons?.layout"
          :like="buttons.like"
          :closer="buttons?.closer"
          @close="emit('close')"
        />
      </div>

      <TeaserListBanner
        v-if="banner"
        :layout="banner.layout"
        :content="banner.content"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { List } from './models';

const emit = defineEmits(['close']);

const { fig, text, buttons, banner } = defineProps<List>();
</script>

<style src="./List.scss" scoped lang="scss"></style>
