<template>
  <div :class="{ showHint: showHint }" class="entry">
    <div class="container">
      <ItineraryEntryHint :content="hint.content" />
      <ItineraryEntryButton @enter="emit('enter')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Entry } from './models';

const props = defineProps<Entry>();
const emit = defineEmits(['enter']);

const hint = computed(() => props.hint);
const showHint = ref(true);

onMounted(() => {
  /* hide hint after 5 seconds */
  setTimeout(() => {
    showHint.value = false;
  }, 3000);
});
</script>

<style src="./Entry.scss" scoped lang="scss"></style>
