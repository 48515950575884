<template>
  <div class="buttons">
    <ClientOnly>
      <TeaserListButtonsLike
        v-if="widgetConfig?.quickAccessList && isLocalStorageAvailable()"
        :content="like?.content"
        :config="{ ...like?.config, isActive: isLiked }"
        :layout="like?.layout"
        @click="toggleLike"
      />
    </ClientOnly>

    <TeaserListButtonsCloser
      v-if="config?.hasCloser"
      :content="closer?.content"
      :config="closer?.config"
      @click="emit('close')"
    />
  </div>
</template>

<script lang="ts" setup>
import isLocalStorageAvailable from '@utils/isLocalStorageAvailable';
import type { Buttons } from './models';
const widgetConfig = await useWidgetConfig();
const bookmarkStore = useBookmarkStore();

const emit = defineEmits(['close']);

const { config, like, closer } = defineProps<Buttons>();

const itemKey = computed(() => `${config?.moduleType}_${config?.id}`);

const isLiked = computed(() => {
  if (!config?.moduleType || !config?.id) return false;

  return bookmarkStore.contains(toValue(itemKey));
});

const toggleLike = () => {
  if (!config?.moduleType || !config?.id) return;

  if (isLiked.value) {
    bookmarkStore.removeBookmark(toValue(itemKey));
  } else {
    bookmarkStore.addBookmark(toValue(itemKey));
  }
};
</script>

<style src="./Buttons.scss" scoped lang="scss"></style>
