<template>
  <button class="button" @click="emit('enter')">
    <BaseIcon class="icon" name="ion:sparkles" />
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits(['enter']);
</script>

<style src="./Button.scss" scoped lang="scss"></style>
